import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import useStyles from 'apputil/view-styles';

import { Paper } from '@material-ui/core';

const SkeletonDeleteCardView = () => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <Skeleton animation="wave" height={50} className={styles.bodyHeader}/>
        <Paper className={styles.paper} elevation={4}>
          <div className={styles.body2Indent}>
            <Skeleton animation="wave" height={25} className={styles.bodyHeader}/>
            <Skeleton animation="wave" height={100} width={250} />
            <Skeleton animation="wave" height={100} className={styles.bodyHeader}/>
          </div>
        </Paper>
        <SkeletonFooter/>
      </>
  );
};

export default SkeletonDeleteCardView;
