import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import { SkeletonHomeAdvertisingView, SkeletonHomeView } from 'views/home/skeleton';
import SkeletonDeleteCardView from 'views/loyalty-card/delete/skeleton';

import { getPassesById } from 'state/selectors';
import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

const LazyDeleteLoyaltyCard = loadable(() => import('suspended-page/loyalty-card/delete'));

const DeleteLoyaltyCardPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const [canDelete, setCanDelete] = useState(true);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  const { homePageOnly = () => {} } = useNavigationContext();
  const authenticated = isAuthenticated();

  useEffect(() => {
    const onFindPass = async () => {
      const { state = '', name = '' } = await getPassesById(passId);
      setCanDelete(state && state !== 'active');
      setTitle(name);
    };

    if (passId) {
      onFindPass();
    }

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (authenticated && loading) {
    return (<SkeletonDeleteCardView />);
  }

  if (authenticated && !canDelete) {
    homePageOnly();
    return (<SkeletonHomeView />);
  }

  return (<LazyDeleteLoyaltyCard passId={passId} title={title}/>);
};

DeleteLoyaltyCardPage.propTypes = {
  location: object,
};

DeleteLoyaltyCardPage.defaultProps = {
  location: {},
};

export default DeleteLoyaltyCardPage;
